<template>
    <div class="common-checkbox">
        <div class="checkbox-box">
            <img v-if="val === 1" src="../../assets/images/bridge/pc/true.png" />
            <img v-if="val === 2" class="false" src="../../assets/images/bridge/pc/false.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckBox',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
        value: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            val: this.value
        }
    },
    watch: {
        value(v) {
            this.val = v
        }
    }
}
</script>

<style lang="less">
.common-checkbox {
    width: 36px;
    height: 36px;
    display: flex;
    padding: 6px;
    box-sizing: border-box;
    .checkbox-box {
        border: solid 3px #fff;
        border-radius: 5px;
        flex: 1;
        position: relative;
        img  {
            position: absolute;
            top: -5px;
            &.false {
                top: -2px;
                left: -2px;
            }
        }
    }
}
</style>
